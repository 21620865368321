import Stripe from 'stripe';
import { TopicDifficulty, TopicRole } from './questions';

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type UserWithDetailsRaw = {
  given_name?: string;
  family_name?: string;
  nickname?: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
};

export type ApiErrorDetails = {
  error: string;
  description: string;
  details?: {
    email?: string;
  };
};

export type UserMeResponse = {
  id: string;
  email: string;
  isEmailVerified: boolean;
  name: string;
  givenName: string | null;
  familyName: string | null;
  pictureUrl: string;
  whitelistedTopicIds: string[];
} & (
  | {
      hasSubscription: true;
      subscriptionName: string;
      accessEndTimestamp: number;
      isSubscriptionCancelled: boolean;
      isPaymentPastDue: boolean;
    }
  | {
      hasSubscription: false;
      subscriptionName: null;
      accessEndTimestamp: null;
      isSubscriptionCancelled: null;
      isPaymentPastDue: null;
    }
);

export type LessonProgressResponse = {
  lessonProgress: {
    id: number;
  };
};

export type AnswerQuestionRequest = {
  lessonProgressId: number;
  questionTemplateId: string;
  questionData: Record<string, unknown>;
  isCorrect: boolean;
  durationSeconds: number;
};

export type AnswerQuestionResponse = {
  answeredQuestion: {
    id: number;
  };
};

export const CATEGORIES = {
  PAYMENT: 'payment',
  SCROLL: 'scroll',
  PAGE_METADATA: 'page_metadata',
  NEWSLETTER: 'newsletter',
  FEEDBACK: 'feedback',
  LANDING: 'landing',
  BUSINESS_LANDING: 'business_landing',
  BUSINESS_WAITLIST: 'business_waitlist',
  CONSUMER_WAITLIST: 'consumer_waitlist',
  FOOTER: 'footer',
  DASHBOARD: 'dashboard',
  COURSE: 'course',
  LESSON: 'lesson',
  QUESTION_MULTIPLE_CHOICE: 'question_multiple_choice',
  QUESTION_ORDERING: 'question_ordering',
  QUESTION_EDITOR: 'question_editor',
  LOGO: 'logo',
  SETTINGS: 'settings',
  BILLING: 'billing',
  FAQ_CONSUMER: 'faq_consumer',
  FAQ_BUSINESS: 'faq_business',
  FORBIDDEN: 'forbidden',
  ERROR_BOUNDARY: 'error_boundary',
  BUSINESS_REGISTER: 'business_register',
  AUTH_ERROR: 'auth_error',
  PROJECT_DETAILS: 'project_details',
  CERTIFICATE: 'certificate',
  LINKEDIN_ADD_TO_PROFILE_BUTTON: 'linkedin_add_to_profile_button',
  LINKEDIN_SHARE_BUTTON: 'linkedin_share_button',
  TWITTER_SHARE_BUTTON: 'twitter_share_button',
  CERTIFICATE_MODAL: 'certificate_modal',
} as const;

type ValueOf<T> = T[keyof T];
export type Category = ValueOf<typeof CATEGORIES>;

export type EventPayload =
  | {
      name: 'loadPage';
    }
  | {
      name: 'errorBoundaryCaughtError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'scroll';
      metadata: {
        direction: 'up' | 'down';
        amount: number;
      };
    }
  | {
      name: 'scrollHitTop';
    }
  | {
      name: 'scrollHitBottom';
    }
  | {
      name: 'changeBusinessWaitlistEmail';
    }
  | {
      name: 'changeBusinessWaitlistName';
    }
  | {
      name: 'changeBusinessWaitlistCompanyWebsite';
    }
  | {
      name: 'openBusinessWaitlistModal';
    }
  | {
      name: 'closeBusinessWaitlistModal';
    }
  | {
      name: 'submitBusinessWaitlistInvalid';
      metadata: {
        invalidFields: string[];
      };
    }
  | {
      name: 'submitBusinessWaitlistStart';
      metadata: {
        email: string;
      };
    }
  | {
      name: 'submitBusinessWaitlistSuccess';
    }
  | {
      name: 'submitBusinessWaitlistError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'submitBusinessWaitlistEnd';
    }
  | {
      name: 'openConsumerWaitlistModal';
    }
  | {
      name: 'closeConsumerWaitlistModal';
    }
  | {
      name: 'changeEditorQuestionSqlAnswer';
      metadata: {
        conceptId: string;
        questionId: string;
        isCorrect: boolean;
      };
    }
  | {
      name: 'changeFeedbackEmail';
    }
  | {
      name: 'changeFeedbackMessage';
    }
  | {
      name: 'openFeedbackEmailModal';
    }
  | {
      name: 'closeFeedbackEmailModal';
    }
  | {
      name: 'submitFeedbackFormMessage';
      metadata: {
        message: string;
      };
    }
  | {
      name: 'submitFeedbackFormStart';
      metadata: {
        email: string | null;
      };
    }
  | {
      name: 'submitFeedbackFormSuccess';
    }
  | {
      name: 'submitFeedbackFormError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'submitFeedbackFormEnd';
    }
  | {
      name: 'clickFeedbackWithEmail';
    }
  | {
      name: 'clickFeedbackWithoutEmail';
    }
  | {
      name: 'hideFeedbackEmailFormResetMessage';
    }
  | {
      name: 'hideFeedbackEmailFormKeepMessage';
    }
  | {
      name: 'leftClickLink';
      metadata: {
        label: string;
        url?: string;
        extra?: { [key: string]: string | number | boolean | null };
      };
    }
  | {
      name: 'rightClickLink';
      metadata: {
        label: string;
        url?: string;
        extra?: { [key: string]: string | number | boolean | null };
      };
    }
  | {
      name: 'completeLesson';
      metadata: {
        conceptId: string;
        lessonProgressId: number;
        answeredCount: number;
        correctCount: number;
        didPass: boolean;
      };
    }
  | {
      name: 'goToNextLessonQuestion';
      metadata: {
        conceptId: string;
        lessonProgressId: number;
        questionId: string;
        questionIndex: number;
      };
    }
  | {
      name: 'clickLessonSkipQuestion';
      metadata: {
        conceptId: string;
        lessonProgressId: number;
        questionId: string;
        questionIndex: number;
      };
    }
  | {
      name: 'clickLessonCheckAnswer';
      metadata: {
        conceptId: string;
        lessonProgressId: number;
        questionId: string;
        questionIndex: number;
      };
    }
  | {
      name: 'clickLessonNextQuestion';
      metadata: {
        conceptId: string;
        lessonProgressId: number;
        questionId: string;
        questionIndex: number;
      };
    }
  | {
      name: 'clickLessonBegin';
      metadata: {
        conceptId: string;
        lessonProgressId: number;
        questionId: string;
        questionIndex: number;
      };
    }
  | {
      name: 'closeLesson';
      metadata: {
        conceptId: string;
        lessonProgressId: number;
      };
    }
  | {
      name: 'clickLogo';
      metadata: {
        url: string;
      };
    }
  | {
      name: 'clickMultipleChoiceQuestionOption';
      metadata: {
        conceptId: string;
        questionId: string;
        previousIndex: number | null;
        newIndex: number | null;
        isCorrect: boolean;
      };
    }
  | {
      name: 'changeNewsletterFormEmail';
    }
  | {
      name: 'submitNewsletterFormStart';
      metadata: {
        email: string | null;
      };
    }
  | {
      name: 'submitNewsletterFormSuccess';
    }
  | {
      name: 'submitNewsletterFormError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'submitNewsletterFormEnd';
    }
  | {
      name: 'hideOrderingQuestionHelpText';
    }
  | {
      name: 'addOrderingQuestionPart';
      metadata: {
        conceptId: string;
        questionId: string;
        isComplete: boolean;
        isCorrect: boolean;
      };
    }
  | {
      name: 'removingOrderingQuestionPart';
      metadata: {
        conceptId: string;
        questionId: string;
      };
    }
  | {
      name: 'changePaymentEmail';
    }
  | {
      name: 'changePaymentCardName';
    }
  | {
      name: 'changePaymentCountry';
      metadata: {
        country: string;
      };
    }
  | {
      name: 'openPaymentModal';
    }
  | {
      name: 'closePaymentModal';
    }
  | {
      name: 'changePaymentCardWithError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'changePaymentCardWithoutError';
    }
  | {
      name: 'submitPaymentCardInvalid';
      metadata: {
        invalidFields: string[];
      };
    }
  | {
      name: 'submitPaymentCardStripeNotLoaded';
    }
  | {
      name: 'submitPaymentCardNoCardElementFound';
    }
  | {
      name: 'submitPaymentCardStart';
    }
  | {
      name: 'submitPaymentCardSuccess';
    }
  | {
      name: 'submitPaymentCardError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'submitPaymentCardEnd';
    }
  | {
      name: 'submitPaymentMethodProfileNotLoaded';
    }
  | {
      name: 'submitPaymentMethodStripeNotLoaded';
    }
  | {
      name: 'submitPaymentMethodMissingSessionId';
    }
  | {
      name: 'submitPaymentMethodStart';
    }
  | {
      name: 'submitPaymentMethodCardError';
      metadata: {
        error: string | null;
      };
    }
  | {
      name: 'submitPaymentMethodIncompleteError';
      metadata: {
        error: string | null;
      };
    }
  | {
      name: 'submitPaymentMethodOtherError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'submitPaymentMethodUnknownError';
    }
  | {
      name: 'submitPaymentMethodSuccess';
    }
  | {
      name: 'submitPaymentMethodEnd';
    }
  | {
      name:
        | 'clickConsumerLandingPageCtaButton'
        | 'clickBusinessLandingPageCtaButton'
        | 'clickBusinessRegisterPageCtaButton'
        | 'clickTermsPageCtaButton'
        | 'clickPrivacyPageCtaButton'
        | 'clickAboutPageCtaButton'        
        | 'clickCertificatePageCtaButton'
        | 'clickBusinessWaitlistButton';
      metadata: {
        buttonText: string;
      };
    }
  | {
      name: 'changePageRouteComplete';
      metadata: {
        url: string;
      };
    }
  | {
      name: 'clickCourseBackToDashboard';
    }
  | {
      name: 'clickCourseNextLessonNewTopic';
      metadata: {
        topicId: string;
        conceptId: string;
      };
    }
  | {
      name: 'clickCourseNextLessonNewConcept';
      metadata: {
        topicId: string;
        conceptId: string;
      };
    }
  | {
      name: 'clickCourseNextLessonSameConcept';
      metadata: {
        topicId: string;
        conceptId: string;
      };
    }
  | {
      name: 'clickCourseRetryLesson';
      metadata: {
        topicId: string;
        conceptId: string;
      };
    }
  | {
      name: 'cancelSubscriptionNotSubscribed';
    }
  | {
      name: 'cancelSubscriptionStart';
    }
  | {
      name: 'cancelSubscriptionSuccess';
    }
  | {
      name: 'cancelSubscriptionEnd';
    }
  | {
      name: 'cancelSubscriptionError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'reactivateSubscriptionStart';
    }
  | {
      name: 'reactivateSubscriptionSuccess';
    }
  | {
      name: 'reactivateSubscriptionEnd';
    }
  | {
      name: 'reactivateSubscriptionError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'manageBillingRedirectToSubscribe';
    }
  | {
      name: 'manageBillingClickSubscribe';
    }
  | {
      name: 'manageBillingClickReactivate';
    }
  | {
      name: 'manageBillingClickAddCard';
    }
  | {
      name: 'addPaymentMethodToDbStart';
      metadata: {
        paymentMethodId: string;
      };
    }
  | {
      name: 'addPaymentMethodToDbEnd';
      metadata: {
        paymentMethodId: string;
      };
    }
  | {
      name: 'addPaymentMethodToDbSuccess';
      metadata: {
        paymentMethodId: string;
      };
    }
  | {
      name: 'addPaymentMethodToDbError';
      metadata: {
        paymentMethodId: string;
        error: string;
      };
    }
  | {
      name: 'subscribeFormAlreadySubscribed';
    }
  | {
      name: 'subscribeFormOnSuccess';
    }
  | {
      name: 'subscribeFormStart';
      metadata: {
        paymentMethodId: string | null;
      };
    }
  | {
      name: 'subscribeFormError';
      metadata: {
        paymentMethodId: string | null;
        error: string;
      };
    }
  | {
      name: 'subscribeFormSuccess';
      metadata: {
        paymentMethodId: string | null;
      };
    }
  | {
      name: 'subscribeFormUnexpectedStatus';
      metadata: {
        paymentMethodId: string | null;
        status: string;
      };
    }
  | {
      name: 'subscribeFormEnd';
      metadata: {
        paymentMethodId: string | null;
      };
    }
  | {
      name: 'subscribeFormAddPaymentMethodStart';
      metadata: {
        paymentMethodId: string;
      };
    }
  | {
      name: 'subscribeFormAddPaymentMethodSuccess';
      metadata: {
        paymentMethodId: string;
      };
    }
  | {
      name: 'subscribeFormAddPaymentMethodError';
      metadata: {
        paymentMethodId: string;
        error: string;
      };
    }
  | {
      name: 'subscribeFormAddPaymentMethodEnd';
      metadata: {
        paymentMethodId: string;
      };
    }
  | {
      name: 'subscribeFormSubscribeStart';
    }
  | {
      name: 'subscribeFormSubscribeSuccess';
      metadata: {
        subscriptionStatus: string;
      };
    }
  | {
      name: 'subscribeFormSubscribeError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'subscribeFormSubscribeEnd';
    }
  | {
      name: 'subscribeFormSubscribeRequiresPaymentMethod';
    }
  | {
      name: 'subscribeFormConfirmCardPaymentStart';
    }
  | {
      name: 'subscribeFormConfirmCardPaymentSuccess';
    }
  | {
      name: 'subscribeFormConfirmCardPaymentUnexpectedStatus';
      metadata: {
        status: string;
      };
    }
  | {
      name: 'subscribeFormConfirmCardPaymentStripeError';
      metadata: {
        error: string;
        errorDetails: string;
      };
    }
  | {
      name: 'subscribeFormConfirmCardPaymentOtherError';
      metadata: {
        error: string;
      };
    }
  | {
      name: 'subscribeFormConfirmCardPaymentEnd';
    }
  | {
      name: 'subscribeFormClickSubscribe';
    }
  | {
      name: 'subscribeFormOnPaymentMethodSuccess';
      metadata: {
        paymentMethodId: string;
      };
    }
  | {
      name: 'subscribeFormShowPaymentMethodForm';
    }
  | {
      name: 'subscribeFormHidePaymentMethodForm';
    }
  | {
      name: 'subscribeFormClickTryAgain';
    }
  | {
      name: 'subscribeFormClickStartLearning';
    }
  | {
      name: 'clickAddToLinkedInProfile';
      metadata: {
        url: string;
        certificateId: string;
      };
    }
  | {
      name: 'clickShareOnLinkedIn';
      metadata: {
        url: string;
      };
    }
  | {
      name: 'clickShareOnTwitter';
      metadata: {
        url: string;
        text?: string;
        via?: string;
        hashtags?: string[];
      };
    }
  | {
      name: 'openCertificateModal';
      metadata: {
        certificateId: string;
      };
    }
  | {
      name: 'closeCertificateModal';
      metadata: {
        certificateId: string;
      };
    };

export type AnalyticsEvent = {
  eventTimestamp: number;
  eventId: string;
  eventName: string;
  eventMetadata: { [key: string]: any };
  clientId: string;
  sessionId: string;
  userId: string | null;
  clientMetadata: { [key: string]: any };
};

export type SendProductUpdatesSetting = {
  key: 'send_product_updates';
  value: boolean;
};

export type SendWeeklyLessonRemindersSetting = {
  key: 'send_weekly_lesson_reminders';
  value: boolean;
};

export type UserSetting =
  | SendProductUpdatesSetting
  | SendWeeklyLessonRemindersSetting;

export type UserSettingsPayload = {
  settings: UserSetting[];
};

export type SubscriptionInterval = 'monthly' | 'yearly';

export type CurrentSubscription =
  | {
      state: 'unsubscribed';
      planName: null;
      interval: null;
      nextCharge: null;
      accessEndTimestamp: null;
      paymentMethod: string | null;
    }
  | {
      state: 'subscribed';
      planName: string;
      interval: SubscriptionInterval;
      nextCharge: {
        price: number;
        currency: string;
        timestamp: number;
        isDue: boolean;
      };
      accessEndTimestamp: number;
      paymentMethod: string | null;
    }
  | {
      state: 'cancelled';
      planName: string;
      interval: SubscriptionInterval;
      nextCharge: {
        price: number;
        currency: string;
        timestamp: number;
        isDue: boolean;
      };
      accessEndTimestamp: number;
      paymentMethod: string | null;
    };

export type BillingDetails = {
  currentSubscription: CurrentSubscription;
  paymentInvoices: {
    invoiceId: string;
    timestamp: number;
    price: number;
    currency: string;
    method: string;
    url: string | null;
  }[];
};

export type SubscriptionResponse = {
  subscription: {
    status: Stripe.Subscription.Status;
    paymentIntentStatus: Stripe.PaymentIntent.Status | null;
    clientSecret: string | null;
  };
};

export type PaymentMethodResponse = {
  paymentMethod: {
    label: string;
  };
};

export type EmailWhitelist = {
  id: number;
  email: string;
  is_active: boolean;
};

export type EmailWhitelistResponse = {
  emailWhitelist: EmailWhitelist[];
};

export const FreeTopicIdValues = [
  'select_from_limit',
  'order_clause',
  'where_clause',
  'inspecting_user_actions',
  'group_by_clause',
  'simple_summary',
  'scalar_transformations',
  'simple_summary_with_transformation',
  'pipelines',
  'window_functions',
  'joins',
  'core_sql',
] as const;
export type FreeTopicId = typeof FreeTopicIdValues[number];

type StreamProject = {
  id: string;
  label: string;
  shortLabel: string;
  summaryDescription: string;
  difficulty: TopicDifficulty;
  roles: TopicRole[];
  tags: string[];
  skills: string[];
  icon: string;
  isActive: boolean;
};

type Stream = {
  id: string;
  label: string;
  shortLabel: string;
  icon: string;
  summaryDescription: string;
  fullDescription: string;
  isActive: boolean;
  projects: StreamProject[];
};

type Certificate = {
  id: string;
  createdAt: string;
  expiresAt: string;
};

export type CertificateApiResponse = {
  stream: Stream;
  certificate: Certificate;
  user: {
    id: string;
    name: string;
  };
};

export type CertificatesApiResponse = {
  certificates: {
    id: string;
    streamId: string;
    createdAt: string;
    expiresAt: string;
  }[];
};

export type StreamsApiResponse = {
  streams: Stream[];
};

export type CompleteLessonResponse = CertificatesApiResponse;
