export function setLS(name: string, value: string) {
  const win: any = window;
  if (window.localStorage) {
    try {
      window.localStorage.setItem(name, value);
    } catch (e) {
      if (win.Sentry) {
        win.Sentry.captureException(e);
      }
    }
  }
}

export function getLSWithType<T = string>(
  name: string,
  cast: (raw: string) => T
) {
  const win: any = window;
  let value;
  if (window.localStorage) {
    try {
      value = window.localStorage.getItem(name);
    } catch (e) {
      if (win.Sentry) {
        win.Sentry.captureException(e);
      }
    }
  }
  if (value != null) {
    return cast(value);
  }
  return undefined;
}

export function getLS(name: string) {
  return getLSWithType<string>(name, (value) => String(value));
}

function setCookie(name: string, value: string) {
  let domain = '';
  if (/supercooldata\.com$/.test(window.location.hostname)) {
    domain = '; domain=.supercooldata.com';
  }

  const date = new Date();
  date.setTime(date.getTime() + 2 * 365 * 24 * 60 * 60 * 1000);

  const expires = '; expires=' + date.toUTCString();

  document.cookie = name + '=' + (value || '') + expires + domain + '; path=/';
}

export function getCookie(name: string) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return undefined;
}

export function persistValue(name: string, value: string) {
  setCookie(name, value);
  setLS(name, value);
}

export function getPersistedValue(name: string) {
  let value = getCookie(name);
  if (value == null) {
    value = getLS(name);
    if (value != null) {
      setCookie(name, value);
    }
  } else {
    setLS(name, value);
  }
  return value != null ? value : undefined;
}
