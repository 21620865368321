import dayjs from 'dayjs';
import { CertificateApiResponse } from '../universal/types';
import { createQueryString } from '../universal/util';
import { baseUrl } from './config';

export const popupCenter = ({
  url,
  title,
  w,
  h,
}: {
  url: string;
  title: string;
  w: number;
  h: number;
}) => {
  // Fixes dual-screen position
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = (width - w) / 2 + dualScreenLeft;
  const top = (height - h) / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w}, 
    height=${h}, 
    top=${top}, 
    left=${left}
    `
  );

  console.log('dualScreenLeft', dualScreenLeft);
  console.log('dualScreenTop', dualScreenTop);
  console.log('width', width);
  console.log('height', height);
  console.log('left', left);
  console.log('top', top);
  console.log('w', w);
  console.log('h', h);

  if (newWindow && typeof newWindow.focus === 'function') {
    newWindow.focus();
  }
};

const createUrlWithUtmParams = ({
  url,
  source,
  medium,
}: {
  url: string;
  source: 'twitter' | 'linkedin';
  medium: 'share' | 'linkedin_profile_certificate';
}) => {
  const sep = url.indexOf('?') >= 0 ? '&' : '?';
  return `${url}${sep}${createQueryString({
    utm_source: source,
    utm_medium: medium,
  })}`;
};

export const getTwitterShareUrl = ({
  url,
  text,
  via,
  hashtags,
}: {
  url: string;
  text?: string;
  via?: string;
  hashtags?: string[];
}) => {
  const params: Record<string, string> = {
    url: createUrlWithUtmParams({
      url,
      source: 'twitter',
      medium: 'share',
    }),
  };
  if (typeof text === 'string' && text.length > 0) {
    params['text'] = text;
  }
  if (typeof via === 'string' && via.length > 0) {
    params['via'] = via;
  }
  if (Array.isArray(hashtags) && hashtags.length > 0) {
    params['hashtags'] = hashtags.join(',');
  }
  const qs = createQueryString(params);
  return `https://twitter.com/intent/tweet?${qs}`;
};

export const getCertificateUrl = (id: string) =>
  `${baseUrl}/certificate?${createQueryString({ certificateId: id })}`;

export const getTwitterShareCertificateParams = (
  certificateId: string,
  certificateLabel: string
) => ({
  url: getCertificateUrl(certificateId),
  text: `Just got my ${certificateLabel} Certification`,
  via: '_supercooldata',
  hashtags: ['supercooldata', 'sql', 'certificate'],
});

export const getTwitterShareCertificateUrl = (
  certificateId: string,
  certificateLabel: string
) =>
  getTwitterShareUrl(
    getTwitterShareCertificateParams(certificateId, certificateLabel)
  );

export const getLinkedInShareUrl = (url: string) =>
  `https://www.linkedin.com/sharing/share-offsite/?${createQueryString({
    url: createUrlWithUtmParams({
      url,
      source: 'linkedin',
      medium: 'share',
    }),
  })}`;

export const shareUrlOnLinkedIn = (url: string) =>
  popupCenter({
    url: getLinkedInShareUrl(url),
    title: '',
    w: 500,
    h: 600,
  });

export const getAddCertificateToLinkedInProfileUrl = (
  certificate: Pick<CertificateApiResponse, 'certificate' | 'stream'>
) => {
  const createdAt = dayjs(certificate.certificate.createdAt).toDate();
  const expiresAt = dayjs(certificate.certificate.expiresAt).toDate();
  const params: Record<string, any> = {
    startTask: 'CERTIFICATION_NAME',
    name: certificate.stream.label,
    organizationId: '53183402', // supercooldata LinkedIn Organisation ID
    issueYear: createdAt.getFullYear(),
    issueMonth: createdAt.getMonth() + 1,
    expirationYear: expiresAt.getFullYear(),
    expirationMonth: expiresAt.getMonth() + 1,
    certUrl: createUrlWithUtmParams({
      url: getCertificateUrl(certificate.certificate.id),
      source: 'linkedin',
      medium: 'linkedin_profile_certificate',
    }),
    certId: certificate.certificate.id,
  };
  return `https://www.linkedin.com/profile/add?${createQueryString(params)}`;
};

export const spliceUrlParameter = (url: string, paramKey: string) => {
  const r = new URL(url);
  const paramValue = r.searchParams.get(paramKey);
  r.searchParams.delete(paramKey);
  return { url: r.href, paramValue };
};

export const replaceUrlWithoutParam = (paramKey: string) => {
  const oldUrl = window.location.href;
  const { url: newUrl, paramValue } = spliceUrlParameter(oldUrl, paramKey);
  if (history.replaceState) {
    history.replaceState(null, '', newUrl);
    console.log(`Replaced URL from ${oldUrl} to ${newUrl}`);
  }
  return paramValue;
};

export const LINK_VISIT_ID_KEY = 'lvid';
