import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import * as Sentry from '@sentry/node';

import { setUserId } from './stats';
import { FreeTopicIdValues, UserMeResponse } from '../universal/types';
import { ApiResponseError, isApiResponseError } from '../frontend/types';
import useApi from './api';

type AuthContextValue = {
  isLoading: boolean;
  isLoggedIn: boolean;
  isForbidden: boolean;
  isSubscribed: boolean | undefined;
  profile: UserMeResponse | null | undefined;
  error: ApiResponseError | null;
  forbiddenEmail: string | null;
  refresh: () => void;
  refreshBilling: () => void;
  canAccessTopic: (topicId: string) => boolean;
};

const AuthContext = createContext<AuthContextValue>({
  isLoading: false,
  isLoggedIn: false,
  isForbidden: false,
  isSubscribed: undefined,
  profile: undefined,
  error: null,
  forbiddenEmail: null,
  refresh: () => null,
  refreshBilling: () => null,
  canAccessTopic: () => false,
});

export const AuthProvider: React.FC = ({ children }) => {
  const { api, lastApiResponseStatus, lastApiResponseError } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [profile, setProfile] = useState<UserMeResponse | null | undefined>(
    undefined
  );
  const [error, setError] = useState<ApiResponseError | null>(null);
  const [forbiddenEmail, setForbiddenEmail] = useState<string | null>(null);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [needsRefreshBilling, setNeedsRefreshBilling] = useState(false);

  const refresh = useCallback(() => {
    setNeedsRefresh(true);
  }, []);

  const refreshBilling = useCallback(() => {
    setNeedsRefreshBilling(true);
  }, []);

  useEffect(() => {
    switch (lastApiResponseStatus) {
      case 401: {
        setProfile(null);
        setUserId(null);
        break;
      }
      case 403: {
        if (isApiResponseError(lastApiResponseError)) {
          setIsForbidden(true);
          if (
            lastApiResponseError &&
            lastApiResponseError.details &&
            lastApiResponseError.details.details &&
            typeof lastApiResponseError.details.details.email === 'string' &&
            lastApiResponseError.details.details.email.length > 0
          ) {
            setForbiddenEmail(lastApiResponseError.details.details.email);
          }
        }
        break;
      }
    }
  }, [lastApiResponseStatus, lastApiResponseError]);

  useEffect(() => {
    if (profile === undefined || needsRefresh) {
      setNeedsRefresh(false);
      setIsLoading(true);
      setError(null);
      (async () => {
        try {
          const _profile = await api.getMe();
          setProfile(_profile);
          Sentry.setTag('userId', _profile ? _profile.id : 'undefined');
          setUserId(_profile === null ? null : _profile.id);
        } catch (e) {
          if (!(isApiResponseError(e) && e.response.status === 403)) {
            setError(e);
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [profile, api, needsRefresh]);

  useEffect(() => {
    if (needsRefreshBilling) {
      setNeedsRefreshBilling(false);
      (async () => {
        await api.refreshSubscription();
        setNeedsRefresh(true);
      })();
    }
  }, [api, needsRefreshBilling]);

  const value = useMemo(() => {
    const isSubscribed =
      profile === undefined
        ? undefined
        : profile === null
        ? false
        : profile.hasSubscription;
    return {
      isLoading,
      isLoggedIn: !!profile,
      isForbidden,
      isSubscribed,
      profile,
      error,
      forbiddenEmail,
      refresh,
      refreshBilling,
      canAccessTopic: (topicId: string) =>
        profile
          ? isSubscribed ||
            FreeTopicIdValues.some((id) => id === topicId) ||
            profile.whitelistedTopicIds.includes(topicId)
          : false,
    };
  }, [
    isLoading,
    isForbidden,
    profile,
    error,
    forbiddenEmail,
    refresh,
    refreshBilling,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
