import { getPersistedValue, persistValue, getCookie } from './persist';
import { v4 as uuidv4 } from 'uuid';
import { environment, config } from './config';
import { createApi } from './api';
import {
  CATEGORIES,
  Category,
  AnalyticsEvent,
  EventPayload,
} from '../universal/types';
import * as Sentry from '@sentry/node';
import { SCD_ID_KEY } from '../universal/util';
import { LINK_VISIT_ID_KEY, replaceUrlWithoutParam } from './util';

const { enabled, propertyId: gaPropertyId, maskedUrlPath } = config.google;

const GA_CLIENT_ID_COOKIE = '_ga';
const SCROLL_CHANGE_TO_REPORT = 20;
const SCROLL_CHANGE_TO_HIT_EDGE = 2;

const api = createApi();

type SendEvent = (
  category: Category,
  payload: EventPayload,
  extra?: { [key: string]: string | number }
) => void;

const state: {
  backlog: AnalyticsEvent[];
  initialised: boolean;
  userId: string | null;
} = {
  backlog: [],
  initialised: false,
  userId: null,
};

const flushBacklog = () => {
  if (state.initialised) {
    const events = [...state.backlog];
    state.backlog = [];
    for (const event of events) {
      console.log(`Sent stat event ${event.eventId} to API`, event);
      api.sendStatEvent(event);
    }
  }
};

const { sendEvent: sendEventRaw } = ((): { sendEvent: SendEvent } => {
  const linkVisitId =
    typeof window !== 'undefined'
      ? replaceUrlWithoutParam(LINK_VISIT_ID_KEY)
      : null;
  if (linkVisitId !== null) {
    console.log('Extract link visit id = ', linkVisitId);
  }

  if (!enabled || typeof window === 'undefined') {
    console.log('stats disabled, early out');
    return { sendEvent: () => null };
  }
  console.log('stats enabled');

  const win: any = window;
  const startDate = win.__supercooldata_start_date || new Date();

  win.dataLayer = win.dataLayer || [];
  win.gtag = function gtag(...args: any[]) {
    win.dataLayer.push(args);
  };
  win.gtag('js', startDate);

  const possibleScdId = getPersistedValue(SCD_ID_KEY);
  let scdId: string;
  if (possibleScdId === null || possibleScdId === undefined) {
    scdId = uuidv4();
    persistValue(SCD_ID_KEY, scdId);
  } else {
    scdId = possibleScdId;
  }

  const gaClientId = getCookie(GA_CLIENT_ID_COOKIE);
  const gaSessionId = getCookie('_gid') || '';

  const scdSessionId = uuidv4();

  Sentry.setTag('scdClientId', scdId);
  Sentry.setTag('scdSessionId', scdSessionId);
  Sentry.setTag('gaClientId', gaClientId || 'undefined');
  Sentry.setTag('gaSessionId', gaSessionId || 'undefined');

  function getMetadata() {
    return {
      timestamp: new Date().getTime(),
      eventId: uuidv4(),
      scdId,
      clientId: gaClientId,
      sessionId: gaSessionId,
      userId: state.userId,
    };
  }

  win.gtag('config', gaPropertyId, {
    custom_map: {
      dimension1: 'page_metadata',
    },
    page_metadata: JSON.stringify(getMetadata()),
  });

  const sendEvent: SendEvent = (category, payload, originalExtra) => {
    const extra = { ...originalExtra };
    extra.event_category = category;
    const metadata = getMetadata();
    extra.page_metadata = JSON.stringify(metadata);
    win.gtag('event', payload.name, extra);

    Sentry.addBreadcrumb({
      category: payload.name,
      message: String(extra.event_label),
      level: Sentry.Severity.Info,
    });
    const referrer = win.document.referrer;
    const currentDateTime = new Date();
    const dateFormatOptions =
      typeof Intl !== 'undefined' &&
      typeof Intl.DateTimeFormat === 'function' &&
      typeof Intl.DateTimeFormat().resolvedOptions === 'function'
        ? Intl.DateTimeFormat().resolvedOptions()
        : null;
    state.backlog.push({
      eventTimestamp: metadata.timestamp,
      eventId: metadata.eventId,
      eventName: payload.name,
      eventMetadata: 'metadata' in payload ? payload.metadata : {},
      clientId: metadata.scdId,
      sessionId: scdSessionId,
      userId: metadata.userId,
      clientMetadata: {
        url: win.location.href,
        userAgent: win.navigator.userAgent,
        referrer:
          typeof referrer === 'string' && referrer.length > 0 ? referrer : null,
        language: win.navigator.language,
        screenWidth: win.screen.width,
        screenHeight: win.screen.height,
        pageWidth: win.innerWidth,
        pageHeight: win.innerHeight,
        devicePixelRatio: win.devicePixelRatio,
        localDateTime: currentDateTime.toString(),
        ...(typeof currentDateTime.getTimezoneOffset === 'function'
          ? { timezoneOffset: currentDateTime.getTimezoneOffset() }
          : null),
        ...(dateFormatOptions !== null &&
        typeof dateFormatOptions.locale === 'string'
          ? {
              locale: dateFormatOptions.locale,
            }
          : null),
        ...(dateFormatOptions !== null &&
        typeof dateFormatOptions.timeZone === 'string'
          ? {
              timezoneName: dateFormatOptions.timeZone,
            }
          : null),
        ...(typeof linkVisitId === 'string' && linkVisitId.length > 0
          ? { linkVisitId }
          : null),
      },
    });
    flushBacklog();
  };

  sendEvent(CATEGORIES.PAGE_METADATA, {
    name: 'loadPage',
  });

  const scrollState = {
    scrollY: 0,
    lastReportedRatio: 0,
    didReportTopHit: false,
    didReportBottomHit: false,
  };
  document.addEventListener('scroll', () => {
    const lastScrollY = scrollState.scrollY;
    scrollState.scrollY = document.documentElement.scrollTop;
    const direction = scrollState.scrollY > lastScrollY ? 'down' : 'up';
    const documentHeight =
      document.documentElement.scrollHeight != null
        ? document.documentElement.scrollHeight
        : document.body.scrollHeight;
    const maxScrollY = documentHeight - window.innerHeight;
    const ratio = Math.round((scrollState.scrollY / maxScrollY) * 100);
    const didChangeEnough =
      Math.abs(ratio - scrollState.lastReportedRatio) >=
      SCROLL_CHANGE_TO_REPORT;
    const shouldReport =
      didChangeEnough ||
      (ratio !== scrollState.lastReportedRatio &&
        (ratio === 0 || ratio === 100));
    if (shouldReport) {
      sendEvent(
        CATEGORIES.SCROLL,
        {
          name: 'scroll',
          metadata: {
            direction,
            amount: ratio,
          },
        },
        {
          event_label: ratio,
        }
      );
      scrollState.lastReportedRatio = ratio;
    }
    if (
      direction === 'up' &&
      ratio <= SCROLL_CHANGE_TO_HIT_EDGE &&
      !scrollState.didReportTopHit
    ) {
      sendEvent(CATEGORIES.SCROLL, {
        name: 'scrollHitTop',
      });
      scrollState.didReportTopHit = true;
    }
    if (
      direction === 'down' &&
      ratio >= 100 - SCROLL_CHANGE_TO_HIT_EDGE &&
      !scrollState.didReportBottomHit
    ) {
      sendEvent(CATEGORIES.SCROLL, {
        name: 'scrollHitBottom',
      });
      scrollState.didReportBottomHit = true;
    }
    if (direction === 'up' && ratio < 100 - SCROLL_CHANGE_TO_REPORT) {
      scrollState.didReportBottomHit = false;
    }
    if (direction === 'down' && ratio > SCROLL_CHANGE_TO_REPORT) {
      scrollState.didReportTopHit = false;
    }
  });

  const a = document.createElement('script') as HTMLScriptElement;
  a.async = true;
  a.src = `https://stats.supercooldata.com/${maskedUrlPath}`;
  console.log('Loading script from ' + a.src);

  const m = document.getElementsByTagName('script')[0];
  if (m && m.parentNode) {
    m.parentNode.insertBefore(a, m);
  } else {
    document.body.appendChild(a);
  }

  return { sendEvent };
})();

const sendEvent: SendEvent = (...args) => {
  if (environment === 'local' || !enabled) {
    console.log('sendEvent()', ...args);
  }
  return sendEventRaw(...args);
};

const setUserId = (userId: string | null) => {
  state.userId = userId;
  if (!state.initialised && userId !== null) {
    for (const event of state.backlog) {
      event.userId = userId;
    }
  }
  state.initialised = true;
  flushBacklog();
};

export { sendEvent, setUserId };
