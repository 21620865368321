import { TopicRole } from './questions';

export const getRandomItemIndex = (items: any[]): number =>
  Math.floor(Math.random() * items.length);

export const shuffleItems = <T>(items: T[]): T[] => {
  const result: T[] = [];
  const options = [...items];
  while (options.length > 0) {
    const randomIndex = getRandomItemIndex(options);
    const [randomItem] = options.splice(randomIndex, 1);
    result.push(randomItem);
  }
  return result;
};

export const normaliseSqlForComparison = (sql: string): string =>
  sql
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .replace(/\s*,\s*/g, ',')
    .replace(/\s*;\s*/g, ';')
    .replace(/;\s*$/, '')
    .trim();

export const filterRemovable = (text: string): string =>
  text.replace(/ *\[REMOVABLE( - (START|END))?\] */g, '');

export const timeToHoursAndMinutes = (timeInMinutes: number) => {
  let hours = Math.floor(timeInMinutes / 60);
  let minutes = Math.round((timeInMinutes - hours * 60) / 5) * 5;
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  if (timeInMinutes > 0 && hours === 0 && minutes === 0) {
    minutes = 5;
  }
  return { hours, minutes };
};

export const pluralise = (count: number) => (count === 1 ? '' : 's');

export const timeToHoursAndMinutesString = (
  timeInMinutes: number,
  short = false
) => {
  const { hours, minutes } = timeToHoursAndMinutes(timeInMinutes);
  const hour = short ? 'hr' : 'hour' + pluralise(hours);
  const minute = short ? 'min' : 'minute' + pluralise(minutes);
  const and = short ? ' ' : ' and ';
  return (
    (hours > 0 ? `${hours} ${hour}` : '') +
    (hours > 0 && minutes > 0 ? and : '') +
    (minutes > 0 ? `${minutes} ${minute}` : '')
  );
};

export const capitalise = (text: string) =>
  text.substr(0, 1).toUpperCase() + text.substr(1);

export const randomItem = <T>(items: T[]) =>
  items[Math.floor(Math.random() * items.length)];

export const projectRoleLabel = (role: TopicRole) => {
  switch (role) {
    case 'seo':
      return 'SEO';
    default:
      return capitalise(role);
  }
};

export const getNextConceptForTopic = (topic: {
  concepts: {
    id: string;
    isAccessible: boolean;
    isComplete: boolean;
  }[];
}) => {
  const accessibleConcepts = topic.concepts.filter(
    (concept) => concept.isAccessible
  );
  if (accessibleConcepts.length > 0) {
    const incompleteConcepts = accessibleConcepts.filter(
      (concept) => !concept.isComplete
    );
    if (incompleteConcepts.length > 0) {
      return incompleteConcepts[0];
    } else {
      return accessibleConcepts[0];
    }
  }
};

// TODO - delete
// export const createEnum = <T extends string>(values: readonly T[]) => {
//   type CustomEnumType = Record<T, T>;
//   const CustomEnum = values.reduce<CustomEnumType>((result, value) => {
//     result[value] = value;
//     return result;
//   }, {} as CustomEnumType);
//   return CustomEnum
// };

export const getTypedObjectKeys = <T extends string | symbol | number>(
  values: Record<T, any>
) => Object.keys(values) as T[];

export const createBaseUrl = (protocol: string, hostname: string) =>
  `${protocol}://${hostname}`;

export const createQueryString = (
  params: Record<string, string | number | boolean>
) =>
  Object.keys(params)
    .map((key) => [key, encodeURIComponent(params[key])].join('='))
    .join('&');

export const SCD_ID_KEY = '_scdid';

export const uniqueStrings = (values: string[]) => {
  const result: string[] = [];
  for (const value of values) {
    if (!result.includes(value)) {
      result.push(value);
    }
  }
  return result;
};
