import {
  ApiErrorDetails,
  CompleteLessonResponse,
  StreamsApiResponse,
} from '../universal/types';

export type ApiResponseError = Error & {
  response: Response;
  details: Partial<ApiErrorDetails>;
};

export const isApiResponseError = (obj: any): obj is ApiResponseError =>
  obj && obj.response && obj.details && obj instanceof Error;

export type Row = { [key: string]: any };
export type ResultSet = { columns: string[]; rows: Row[] };

export interface BaseQuestionTemplate {
  questionId: string;
  questionText: string;
  questionDifficulty: number;
}

export type MultipleChoiceQuestionTemplate = BaseQuestionTemplate &
  (
    | {
        questionType: 'multiple_choice';
        inputType: 'sql';
        inputValue: string;
        outputType: 'table';
        outputValues: ResultSet[];
        correctChoiceIndex: number;
      }
    | {
        questionType: 'multiple_choice';
        inputType: 'table';
        inputValue: ResultSet;
        outputType: 'sql';
        outputValues: string[];
        correctChoiceIndex: number;
      }
    | {
        questionType: 'multiple_choice';
        outputType: 'text';
        outputValues: string[];
        correctChoiceIndex: number;
      }
    | {
        questionType: 'multiple_choice';
        inputType: 'text';
        inputValue: string;
        outputType: 'sql';
        outputValues: string[];
        correctChoiceIndex: number;
      }
  );

export interface OrderingQuestionTemplate extends BaseQuestionTemplate {
  questionType: 'ordering';
  orderedValues: string[];
  queryTextComplete: string;
  resultShouldShowTable: boolean;
  resultShouldShowChart: boolean;
  queryResultSet: ResultSet;
}

export interface EditorQuestionTemplate extends BaseQuestionTemplate {
  questionType: 'editor';
  queryTextTemplate: string;
  queryTextComplete: string;
  resultShouldShowTable: boolean;
  resultShouldShowChart: boolean;
  queryResultSet: ResultSet;
}

export type QuestionTemplate =
  | MultipleChoiceQuestionTemplate
  | OrderingQuestionTemplate
  | EditorQuestionTemplate;

export type HomeHeaderPage =
  | 'consumer'
  | 'business'
  | 'business-register'
  | 'terms'
  | 'about'  
  | 'privacy';

export type NewCertificate = {
  certificate: CompleteLessonResponse['certificates'][number];
  stream: StreamsApiResponse['streams'][number];
};
