import { useMemo, useState } from 'react';
import * as Sentry from '@sentry/node';

import {
  ApiErrorDetails,
  UserMeResponse,
  LessonProgressResponse,
  AnswerQuestionRequest,
  AnswerQuestionResponse,
  AnalyticsEvent,
  UserSettingsPayload,
  BillingDetails,
  SubscriptionResponse,
  PaymentMethodResponse,
  EmailWhitelistResponse,
  SubscriptionInterval,
  CertificateApiResponse,
  StreamsApiResponse,
  CertificatesApiResponse,
  CompleteLessonResponse,
} from '../universal/types';
import {
  QuestionsApiResponse,
  ProjectsApiDerivedResponse,
} from '../universal/questions';
import { ApiResponseError, isApiResponseError } from '../frontend/types';
import { createQueryString } from '../universal/util';

export const createApi = (
  onApiResponseStatus?: (status: number, error: ApiResponseError | null) => void
) => ({
  async request<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    url: string,
    data?: Record<string, unknown>
  ): Promise<T | ApiResponseError> {
    let details: Partial<ApiErrorDetails> = {};
    let res: Response | undefined;
    let error: ApiResponseError;
    let status: number | undefined;
    let responseText: string | undefined;

    const fullUrl = `/api/${url}`;
    const body = data !== undefined ? JSON.stringify(data) : undefined;

    const startTime = Date.now();
    let endTime: number | undefined;
    try {
      res = await fetch(fullUrl, {
        method,
        ...(body !== undefined ? { body } : {}),
        headers: {
          ...(data !== undefined ? { 'Content-Type': 'application/json' } : {}),
        },
      });
      endTime = Date.now();

      status = res.status;

      responseText = await res.clone().text();

      if (status >= 200 && status < 300) {
        if (onApiResponseStatus) {
          onApiResponseStatus(status, null);
        }
        return status === 204 ? {} : await res.json();
      }

      if (/application\/json/.test(res.headers.get('content-type') || '')) {
        details = await res.json();
      }

      const message =
        details &&
        details.error !== undefined &&
        details.description !== undefined
          ? details.description
          : details && details.error !== undefined
          ? details.error
          : res.statusText;

      error = new Error(message) as ApiResponseError;
      error.response = res;
      error.details = details;
    } catch (e) {
      error = (typeof e === 'string' ? new Error(e) : e) as ApiResponseError;
      if (res !== undefined) {
        error.response = res;
      }
      error.details = details;
    }

    const statusNumber = typeof status === 'number' ? status : -1;

    if (!(typeof status === 'number' && status >= 400 && status < 500)) {
      Sentry.withScope((scope) => {
        scope.setTag('requestUrl', fullUrl);
        scope.setTag('requestMethod', method);
        scope.setTag('requestBody', body !== undefined ? body : 'undefined');
        scope.setTag('responseStatus', String(statusNumber));
        scope.setTag(
          'responseStatusText',
          res !== undefined ? res.statusText : 'undefined'
        );
        scope.setTag(
          'responseBody',
          responseText !== undefined ? responseText : 'undefined'
        );
        scope.setTag(
          'durationMs',
          String((endTime !== undefined ? endTime : Date.now()) - startTime)
        );
        Sentry.captureException(error);
      });
    }

    if (onApiResponseStatus) {
      onApiResponseStatus(statusNumber, error);
    }

    return error;
  },
  async get<T>(url: string) {
    return this.request<T>('GET', url);
  },
  async post<T>(url: string, data?: Record<string, unknown>) {
    return this.request<T>('POST', url, data);
  },
  async put<T>(url: string, data: Record<string, unknown>) {
    return this.request<T>('PUT', url, data);
  },
  async patch<T>(url: string, data: Record<string, unknown>) {
    return this.request<T>('PATCH', url, data);
  },
  async delete<T>(url: string) {
    return this.request<T>('DELETE', url);
  },
  async subscribeToNewsletter(email: string, isWaitlist = false) {
    await this.post('newsletter', {
      email,
      type: isWaitlist ? 'waitlist' : 'newsletter',
    });
  },
  async sendFeedback(email: string | null, message: string) {
    await this.post('feedback', { email, message });
  },
  async addToWaitlist(email: string, name: string, companyWebsite: string) {
    const result = await this.post('waitlist', { email, name, companyWebsite });
    if (isApiResponseError(result)) {
      throw result;
    }
  },
  async getMe() {
    const result = await this.get<UserMeResponse>('user/me');
    if (isApiResponseError(result)) {
      if (result.response.status === 401) {
        return null;
      }
      throw result;
    }
    return result;
  },
  async getUserSettings() {
    const result = await this.get<UserSettingsPayload>('user/settings');
    if (isApiResponseError(result)) {
      throw result;
    }
    return result.settings;
  },
  async setUserSettings(settings: UserSettingsPayload['settings']) {
    const payload: UserSettingsPayload = { settings };
    const result = await this.post<UserSettingsPayload>(
      'user/settings',
      payload
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result.settings;
  },
  async getProjects() {
    const result = await this.get<ProjectsApiDerivedResponse>(
      'lessons/projects'
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async getQuestions(
    conceptId: string,
    previousVariableGroupId: string | null
  ) {
    const result = await this.get<QuestionsApiResponse>(
      `lessons/questions?${createQueryString({
        conceptId,
        ...(previousVariableGroupId !== null
          ? {
              previousVariableGroupId,
            }
          : {}),
      })}`
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async startLessonProgress(projectId: string, conceptId: string) {
    const result = await this.post<LessonProgressResponse>('lessons/start', {
      projectId,
      conceptId,
    });
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async completeLesson(
    lessonProgressId: number,
    didPassLesson: boolean,
    shouldCheckCertificate: boolean
  ) {
    const result = await this.post<CompleteLessonResponse>('lessons/complete', {
      lessonProgressId,
      didPassLesson,
      shouldCheckCertificate,
    });
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async answerQuestion(payload: AnswerQuestionRequest) {
    const result = await this.post<AnswerQuestionResponse>(
      'lessons/answer',
      payload
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async sendStatEvent(data: AnalyticsEvent) {
    await this.post('stats', data);
  },
  async getBillingDetails() {
    const result = await this.get<BillingDetails>('billing/details');
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async subscribe(interval: SubscriptionInterval) {
    const result = await this.post<SubscriptionResponse>('billing/subscribe', {
      interval,
    });
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async cancelSubscription() {
    const result = await this.post('billing/cancel');
    if (isApiResponseError(result)) {
      throw result;
    }
  },
  async addPaymentMethod(paymentMethodId: string) {
    const result = await this.post<PaymentMethodResponse>(
      'billing/payment-method',
      { paymentMethodId }
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async refreshSubscription() {
    const result = await this.post('billing/refresh');
    if (isApiResponseError(result)) {
      throw result;
    }
  },
  async getEmailWhitelist() {
    const result = await this.get<EmailWhitelistResponse>(
      'admin/email-whitelist'
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async addNewEmailWhitelist(email: string) {
    const result = await this.post('admin/email-whitelist', { email });
    if (isApiResponseError(result)) {
      throw result;
    }
  },
  async updateEmailWhitelist(id: number, isActive: boolean) {
    const result = await this.put('admin/email-whitelist', { id, isActive });
    if (isApiResponseError(result)) {
      throw result;
    }
  },
  async getStreams() {
    const result = await this.get<StreamsApiResponse>('certificates/streams');
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async getCertificate(certificateId: string) {
    const result = await this.get<CertificateApiResponse>(
      `certificates/certificate?${createQueryString({ certificateId })}`
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
  async getCertificates() {
    const result = await this.get<CertificatesApiResponse>(
      'certificates/certificates'
    );
    if (isApiResponseError(result)) {
      throw result;
    }
    return result;
  },
});

const useApi = () => {
  const [lastApiResponseStatus, setLastApiResponseStatus] = useState(0);
  const [
    lastApiResponseError,
    setLastApiResponseError,
  ] = useState<ApiResponseError | null>(null);

  const api = useMemo(
    () =>
      createApi((status: number, error: ApiResponseError | null) => {
        setLastApiResponseStatus(status);
        setLastApiResponseError(error);
      }),
    []
  );

  return { api, lastApiResponseStatus, lastApiResponseError };
};

export default useApi;
