import { createBaseUrl } from '../universal/util';

export const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || 'local';
export const isProd = environment.indexOf('prod') >= 0;
export const isStaging = environment.indexOf('staging') >= 0;

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const config = require(`../../config.frontend.${environment}.json`);

const { protocol: protocolAny, hostname: hostnameAny } = config.web;

if (typeof protocolAny !== 'string') {
  throw new Error(`Invalid config.web.protocol: ${protocolAny}`);
}

if (typeof hostnameAny !== 'string') {
  throw new Error(`Invalid config.web.hostname: ${hostnameAny}`);
}

export const protocol = protocolAny;
export const hostname = hostnameAny;
export const baseUrl = createBaseUrl(protocol, hostname);

export const pricing = {
  consumer: {
    monthly: 39,
    yearly: 19,
  },
  business: 300,
};
